<div
  *ngIf="simpleCounter() as sc"
  class="wrapper"
>
  <ng-container *ngIf="sc.type===SimpleCounterType.StopWatch">
    <button
      (click)="toggleStopwatch()"
      (contextmenu)="edit($event)"
      (longPress)="edit()"
      [color]="sc.isOn? 'accent': ''"
      class="main-btn stopwatch"
      mat-mini-fab
    >
      <mat-icon *ngIf="!sc.isOn">{{ sc.icon }}</mat-icon>
      <mat-icon *ngIf="sc.isOn">{{ sc.iconOn || sc.icon }}</mat-icon>
    </button>
    <div
      *ngIf="sc.countOnDay[todayStr]"
      class="label"
    >
      {{ sc.countOnDay[todayStr]|msToMinuteClockString }}
    </div>
  </ng-container>

  <ng-container *ngIf="sc.type===SimpleCounterType.ClickCounter">
    <button
      (click)="toggleCounter()"
      (contextmenu)="edit($event)"
      (longPress)="edit()"
      class="main-btn"
      color=""
      mat-mini-fab
    >
      <mat-icon>{{ sc.icon }}</mat-icon>
    </button>
    <div
      *ngIf="sc.countOnDay[todayStr]"
      class="label"
    >
      {{ sc.countOnDay[todayStr] }}
    </div>
  </ng-container>

  <ng-container *ngIf="sc.type===SimpleCounterType.RepeatedCountdownReminder">
    <button
      (click)="isTimeUp() ? countUpAndNextRepeatCountdownSession() : toggleStopwatch()"
      (contextmenu)="edit($event)"
      (longPress)="edit()"
      [color]="(sc.isOn && !isTimeUp())? 'accent': ''"
      [class.isTimeUp]="isTimeUp()"
      class="main-btn repeated-countdown"
      mat-mini-fab
    >
      <mat-icon *ngIf="!sc.isOn">{{ sc.icon }}</mat-icon>
      <mat-icon *ngIf="sc.isOn">{{ sc.iconOn || sc.icon }}</mat-icon>
    </button>
    <div
      class="extra-label"
      *ngIf="countdownTime$|async as countdownTime"
    >
      {{ countdownTime|msToMinuteClockString }}
    </div>

    <div
      *ngIf="sc.countOnDay[todayStr] || isTimeUp()"
      class="label"
    >
      {{ isTimeUp() ? "+" : sc.countOnDay[todayStr] }}
    </div>
  </ng-container>

  <!--  <div class="controls">-->
  <!--    <button (click)="edit()"-->
  <!--            class="btn"-->
  <!--            color=""-->
  <!--            [title]="T.G.EDIT|translate"-->
  <!--            mat-mini-fab>-->
  <!--      <mat-icon>edit</mat-icon>-->
  <!--    </button>-->
  <!--    &lt;!&ndash;    <button (click)="reset()"&ndash;&gt;-->
  <!--    &lt;!&ndash;            class="btn"&ndash;&gt;-->
  <!--    &lt;!&ndash;            color=""&ndash;&gt;-->
  <!--    &lt;!&ndash;            [title]="T.G.RESET|translate"&ndash;&gt;-->
  <!--    &lt;!&ndash;            mat-mini-fab>&ndash;&gt;-->
  <!--    &lt;!&ndash;      <mat-icon>undo</mat-icon>&ndash;&gt;-->
  <!--    &lt;!&ndash;    </button>&ndash;&gt;-->
  <!--  </div>-->
</div>
